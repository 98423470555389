import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  input
} from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { filter, Subscription, tap } from 'rxjs';
import { LoginType } from '../../../../core/auth/auth.models';
import { selectAuthError } from '../../../../core/auth/auth.selectors';
import { showSnackbar } from '../../../../core/notifications/notification.actions';
import { NotificationService } from '../../../../core/notifications/notification.service';
import { ErrorMessage } from '../../../../domain/error/error-message.model';
import { retrievePassword } from '../../../../domain/user/user.actions';
import { UserFormService } from '../../../user-form/user-form.service';
import { PermissionVisualizationType } from '../../permission-dialog/permission.model';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


export interface PayloadSignin {
	email: string;
	password: string;
	rememberMe: boolean;
	loginType: LoginType;
}

interface SigninForm {
	email: FormControl<string | null>;
	password: FormControl<string | null>;
	rememberMe: FormControl<boolean>;
}

interface RetrievePwdForm {
	email: FormControl<string | null>;
}

const SIGNUPBASELINK: string = '/signup';

@Component({
    selector: 'dottnet-form-signin',
    templateUrl: './signin-form.component.html',
    styleUrls: ['./signin-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatIconModule, MatButtonModule, TranslateModule]
})
export class SigninFormComponent implements OnInit, OnDestroy {
	//  Reference to access the enum in the template
	readonly PermissionVisualizationType = PermissionVisualizationType;

	@Output()
	readonly formEventEmitter: EventEmitter<PayloadSignin> = new EventEmitter<PayloadSignin>();
	@Output()
	readonly pwdEventEmitter: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	readonly closeDialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

	readonly permissionType = input<PermissionVisualizationType>(PermissionVisualizationType.Signin);

	loginForm: FormGroup<SigninForm>;
	retrievePwdForm: FormGroup<RetrievePwdForm>;

	isChecked: boolean;
	isForgotPasswordForm: boolean = false;
	forgotPasswordLabel: string = this.ts.instant('dottnet.login.pwdforbidden');

	errorMessageSub: Subscription;
	activatedRouteSub: Subscription;


	signupPath: string;

	constructor(
		private store: Store,
		private notificationService: NotificationService,
		private formService: UserFormService,
		private ts: TranslateService,
    private router: Router
	) {}

	ngOnInit(): void {
		this.loginForm = new FormGroup<SigninForm>({
			email: new FormControl('', { validators: [Validators.required, Validators.email] }),
			password: new FormControl('', { validators: [Validators.required] }),
			rememberMe: new FormControl(true)
		});
		this.retrievePwdForm = new FormGroup<RetrievePwdForm>({
			email: new FormControl('', { validators: [Validators.required, Validators.email] })
		});


		const encodedLocation = encodeURIComponent(this.router.url);
		this.signupPath = SIGNUPBASELINK + '?callbackUrl=' + encodedLocation;

		this.isChecked = true;
		this.errorMessageSub = this.store
			.select(selectAuthError)
			.pipe(
				filter((errorMessage: ErrorMessage) => !!errorMessage),
				tap((errorMessage: ErrorMessage) =>
					this.store.dispatch(
						showSnackbar({
							payload: this.notificationService.getErrorNotification(
								errorMessage.Code,
								this.ts.instant('dottnet.error.' + errorMessage.Code),
								'OK',
								0
							)
						})
					)
				)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		if (this.errorMessageSub) this.errorMessageSub.unsubscribe();
		if (this.activatedRouteSub) this.activatedRouteSub.unsubscribe();
	}

	get loginMail() {
		return this.loginForm.get('email');
	}

	get retrieveMail() {
		return this.retrievePwdForm.get('email');
	}

	closeDialog() {
		this.closeDialogEmitter.emit(true);
	}

	switchForm() {
		this.isForgotPasswordForm = !this.isForgotPasswordForm;

		if (this.isForgotPasswordForm) {
			this.forgotPasswordLabel = this.ts.instant('dottnet.menu.login');
			if (this.loginMail.valid) {
				this.retrieveMail.setValue(this.loginMail.value);
			} else {
				this.retrieveMail.setValue(null);
			}
		} else this.forgotPasswordLabel = this.ts.instant('dottnet.login.pwdforbidden');
	}

	get signupLink():string {

		return this.signupPath;

	}

	submitForm() {
		this.formEventEmitter.emit({
			email: this.loginForm.value.email,
			password: this.loginForm.value.password,
			rememberMe: this.loginForm.value.rememberMe,
			loginType: LoginType.HARDLOGIN,
		});
	}

	errorMessage(field: string): string {
		return this.isForgotPasswordForm
			? this.formService.buildErrorMessage(this.retrievePwdForm, field)
			: this.formService.buildErrorMessage(this.loginForm, field);
	}

	retrievePassword() {
		this.store.dispatch(retrievePassword({ email: this.retrievePwdForm.value.email }));
	}
}
